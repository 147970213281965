
import Component from 'vue-class-component'

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);
import { mixins } from 'vue-class-component';
import Icon from '@/components/ui/icon/Icon.vue';
import Dropdown from '@/components/ui/dropdown/Dropdown.vue';
import ConfirmPopup from '@/components/context-menu/repeatable/ConfirmPopup.vue';
import DocumentMetaAudience from '@/modules/documents/components/document/DocumentMetaAudience.vue';
import Input from '@/components/ui/input/Input.vue';
import RolesList from '@/components/user/RolesList.vue';
import Textarea from '@/components/ui/textarea/Textarea.vue';
import UserDataContainer from '@/components/user/UserDataContainer.vue';
import UserDataProperty from '@/components/user/UserDataProperty.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import Button from '@/components/ui/button/Button.vue';
import { HEADERS } from '@/services/helpers/constants';
import Cookies from 'js-cookie';
import UserCard from '@/components/user/UserCard.vue';
// eslint-disable-next-line
// @ts-ignore
import PhoneMaskInput from  'vue-phone-mask-input';
import UserFields from '@/mixins/UserFields';
import UsersModule from '@/store/modules/users/UsersModule';
import ErrorMessage from '@/components/ui/error-message';
import { Specialisations, ValidateFields } from '@/typings/domain';
// eslint-disable-next-line
// @ts-ignore
import DatePicker from 'vue2-datepicker';
import '@/plugins/datapicker/locale';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import Notify from '@/services/helpers/notify';
import axios from 'axios';
import RolesModule from '@/store/modules/roles/RolesModule';
import GeneralModule from '@/store/modules/general/GeneralModule';
import { Watch } from 'vue-property-decorator';
import UserInfo from '@/models/dto/responses/user.response.type';



@Component({
    name: 'UserEdit',
    components: {
        Dropdown,
        DatePicker,
        DocumentMetaAudience,
        Icon,
        Input,
        PhoneMaskInput,
        UserDataContainer,
        UserDataProperty,
        ConfirmPopup,
        ContextMenuSpawner,
        Textarea,
        RolesList,
        Button,
        UserCard,
        ErrorMessage,
    },
})
export default class UserEdit extends mixins(UserFields) {
    public validFaild = false;
    public phoneStatus = false;
    public pageLoaded = false;
    public firstName = '';
    public pageMutated = false;
    public userSetted = false;
    public currentUserData = {} as UserInfo;
    public uploadValid = true;
    public avatarLoaded = false;
    public secondName = ''
    public selectedSpecialisation: Array<number> = []
    public email = '';
    public validateFields: ValidateFields = {};
    public phoneInputInvalid = false;
    public customFields: any = {}
    public phone:string = null;
    public lang = {
        formatLocale: {
            firstDayOfWeek: 1,
        },
        monthBeforeYear: true,
    };
    public userData: any = null;
    public dataPickerValue: string = null;
    public showedOnceLeavePopup = false;
    public confirmContextVisible = false;
    public preventPath = '';
    public savePage = false;
    public userRoles: any = null;
    async loadAvatar(avatar:any)
    {
        this.pageMutated = true;
        await this.fileUploader(avatar[0]).then((data) => 
        {
            this.userData.avatar = data;   
            this.avatarLoaded = true;
        })
    }
    beforeRouteLeave(to: any, from:any, next:any) {
        this.saveUserValues();
        this.matchedUserData()
        if(this.pageMutated)
        {
            
            if(this.isAuthCompleted && !this.savePage)
            {
                this.preventPath = to.path;
                this.confirmContextVisible = true;
                if(this.confirmContextVisible && !this.showedOnceLeavePopup)
                {
                    this.showedOnceLeavePopup = true;
                    return;
                }
            }
        }
        next()
    }
    matchedUserData()
    {   
        for(const key in this.currentUserData)
        {
            if(key === 'fields')
            {
                for(const innerKey in this.currentUserData[key] as any)
                {
                    if(this.currentUserData[key][innerKey].value != this.userData[key][innerKey].value)
                    {
                        this.pageMutated = true;
                        break;
                    }
                }    
            }
            else if(key === 'specialisations')
            {
                for(const innerKey in this.currentUserData[key])
                {
                    if(this.currentUserData[key][innerKey].id != this.userData[key][innerKey])
                    {
                        this.pageMutated = true;
                        break;
                    }
                }
            }
            else
            {
                if(this.userData[key] != this.currentUserData[key])
                {
                    this.pageMutated = true;
                }
            }
        }
    }
    preventRedirect()
    {
        this.showedOnceLeavePopup = false;
        this.confirmContextVisible = false;
    }

    async continuePath()
    {
        this.confirmContextVisible = false
        await this.$nextTick();
        this.$router.push(this.preventPath)
    }
    get currentUser()
    {
        return UsersModule.currentUser;
    }

    // Первая сортировка в засимости от того какой тип поля( custom ,default )
    // Вторая сортировка определяем порядок для default блоков ( order )
    
    get filteredFields()
    {
        return this.fields.sort((a, b) => 
        {
            if(a.field > b.field)
            {
                return -1;
            }
            if(a.field < b.field)
            {
                return 1;
            }
            return 0;
        }).sort((a,b) => 
        {
            if(a.order < b.order)
            {
                return -1;
            }
            return 1;
        })
    }
    get isAuthCompleted()
    {
        return GeneralModule.isAuthCompleted
    }
    get isUserAdmin()
    {
        return UsersModule.currentUser.admin
    }
    // onRoleSelect(id: Array<number>) {
    //     this.innerInvite.specialisations = id;
    //     this.emitChange();
    // }

    
    getValueByField(value: string)
    {
        return 1
    }
    async fileUploader(file: any) 
    {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(`${MAIN_API_HOST}/filesystem/store`, formData, {
            // eslint-disable-next-line
            // @ts-ignore
            authorization: `Bearer ${Cookies.get(HEADERS.AUTH_HEADER)}`,
        })
        return response.data.file.url;
    }
    paginateRoles(page: number)
    {
        RolesModule.fetchRoles({page: page})
    }
    selectedSpec(specs: Array<number>)
    {
        if(this.userData)
        {
            this.selectedSpecialisation = specs;
        }
    }
    assignRole(id: number) {
        UsersModule.addSpecialization({ userId: this.id, specializationId: id });
    }
    validateInput(name: string) 
    {   
        if(name === 'phone')
        {
            Notify.error('Некорректный номер')
            return;
        }
        if(this.validationResult[name]?.isValid === false && this.validationResult[name]?.isValid != undefined)
        {   
            if(name === 'email')
            {
                Notify.error('Некорректный адрес электронной почты')
            }
            else
            {
                Notify.error('Ошибка ввода')
            }
            this.validateFields[name] = false
            this.validFaild = true;
        }
        else
        {   
            this.validateFields[name] = true;
            this.validFaild = false;
        }
        return this.isValid(name) === false
    }
    updateUser(update = true)
    {
        if(update)
        {
            for(const key in this.validateFields)
            {
                if(this.validateFields[key] === false)
                {
                    Notify.error('Данные некорректны')
                    return;
                }
            }
            this.savePage = true;
            if(this.user, this.models?.hobby && (this.models.hobby as string).length > 500)
            {
                Notify.error('Максимальная длина 500 символов')
                return;
            }
            else
            {
                this.saveUserValues()
                this.fetchUpdateUser();
            }
        }
        else
        {
            this.saveUserValues();
        }
    }
    saveUserValues()
    {
        if(this.userData.fields?.email && (this.userData.fields.email === this.user.email))
        delete this.userData.fields.email

        this.userData.status = this.user.status;
        this.models.phone = this.phone;
        this.models['birth_date'] = this.dataPickerValue;
        this.userData.fields.forEach((element: object, index: number) => 
        {
            this.userData.fields[index].value = this.models[this.userData.fields[index].slug]
        });
        delete this.userData.dateOfBirth;

        if(this.firstName)
        {
            this.userData.first_name = this.firstName;
        }
        if(this.secondName)
        {
            this.userData.last_name = this.secondName;
        }
        if(this.email && this.email != this.user.email)
        {
            this.userData.email = this.email;
        }
        this.userData.user_id = Number(this.$route.params.id)
        this.userData.specialisations = this.selectedSpecialisation
    }
    fetchUpdateUser(save = true)
    {
        if(save)
        {
            if(!this.isAuthCompleted)
            {
                this.uploadValid = true;
                delete this.userData.specialisations;
                UsersModule.completeRegistration(this.userData).then(() =>
                {
                    Notify.success('Регистрация завершена')
                    this.$router.replace('/')
                });
            }
            else
            {
                UsersModule.updateUserProfile(this.userData);
                Notify.success('Изменения сохранены')
                this.$router.push(`/team/user/${this.$route.params.id}`)
            }
        }
    }
    setValidate(valid:any)
    {
        if(!valid.isValidByLibPhoneNumberJs)
        {
            this.validFaild = false;
            this.phoneInputInvalid = true
        }
        else
        {
            this.validFaild = true;
            this.phoneInputInvalid = false
        }
    }
    checkValidate()
    {
        // if(this.phoneInputInvalid)
        // {
        //     this.validateFields['phone'] = false;
        //     this.validateInput('phone');
        //     this.phoneStatus = true;
        // }
        // else
        // {
        //     this.validateFields['phone'] = true;   
        //     this.phoneStatus = false;
        // }
    }
    removeRole(id: number) {
        UsersModule.removeSpecialization({ userId: this.id, specializationId: id });
    }
    async created()
    {
        await UsersModule.fetchUser(Number(this.$route.params.id))
        this.initializePage()
        
    }
    public initializePage(): void {
            this.userData = 
            {
                fields: [],
            }
            for (const key in this.user.fields)
            {
                this.userData.fields.push({...this.user.fields[key], slug: key})
            }
            if(this.user.avatar)
            {
                this.avatarLoaded = true;
            }
            
            this.firstName = this.user.first_name;
            this.secondName = this.user.last_name;
            this.email = this.user.email;
            this.phone = this.models['phone'] as string ? this.models['phone'] as string : null;
            this.dataPickerValue = this.models['birth_data'] as string;
    }
    handleRedirect(event: any)
    {
        event.preventDefault();
        event.returnValue = '';
        return ''; 
    }
    async mounted()
    {
        window.addEventListener('beforeunload', this.handleRedirect);
        await this.initializeFields()
        this.pageLoaded = true;
        
    }
    beforeDestroy()
    {
        this.confirmContextVisible = true;
        window.removeEventListener('beforeunload', this.handleRedirect)
    }
    
    @Watch('userData')
    onUserUpdated(val: any)
    {
        this.saveUserValues();
        if(!this.userSetted)
        {   
            this.currentUserData = JSON.parse(JSON.stringify(this.userData));
            this.currentUserData.specialisations = this.user.specialisations
            
            this.userSetted = true;
        }
    }
}
